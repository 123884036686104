import React from "react"
import { graphql, Link } from "gatsby"
import classNames from "classnames/bind"
import styles from "./blog.module.scss"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import BlogJSON from "../../content/PageDataBlog.json"

const cx = classNames.bind(styles)

export const pageQuery = graphql`
  query PostsQuery {
    allPosts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/blog//" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            image
            title
            path
            date(formatString: "YYYY/MM/DD")
            tags
          }
          excerpt(truncate: true, pruneLength: 200)
        }
      }
    }
  }
`

const Blog = ({ location, data }) => {
  return (
    <Layout location={location}>
      <SEO title={"블로그"} />

      <main className="w100">
        <section className={cx("sectionHero")}>
          <div className={cx("heroImage")} />
          <div className={cx("heroHeader")}>
            <div className="sectionContainer">
              <div className={cx("headerWrapper")}>
                <p className={cx("headerMeta")}>{BlogJSON.title}</p>
                <p className={cx("headerTitle")}>{BlogJSON.blogName}</p>
                {/* <p className={cx("headerTitle")}>
                  네이버 포스트, 트립어드바이저, 티스토리
                </p> */}
              </div>
            </div>
          </div>
        </section>

        <section className={cx("sectionPosts")}>
          <div className="sectionContainer">
            <div className={cx("postsWrapper")}>
              {data.allPosts.edges.map(({ node }) => {
                return (
                  <BlogCard
                    key={node.frontmatter.path}
                    imageSrc={node.frontmatter.image}
                    title={node.frontmatter.title}
                    path={`/blog/${node.frontmatter.path}`}
                    date={node.frontmatter.date}
                    excerpt={node.excerpt}
                  />
                )
              })}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

const BlogCard = ({ imageSrc, title, path, date, excerpt }) => {
  return (
    <div className={cx("blogCard")}>
      <div className={cx("contentWrapper")}>
        {imageSrc && (
          <Link to={path}>
            <img
              src={imageSrc}
              alt={title}
              width="100%"
              height="150px"
              className={cx("contentImage")}
            />
          </Link>
        )}
        <div className={cx("content")}>
          <Link to={path}>
            <p className={cx("title")}>{title}</p>
          </Link>
          <p className={cx("meta")}>{date}</p>
          <p className={cx("desc", { topBorder: !imageSrc })}>
            {!imageSrc ? excerpt : excerpt.substring(0, 80).concat("...")}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Blog
